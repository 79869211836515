<template>
  <div class="row" v-if="campaignCreator">
    <div class="col-3">
      <div class="card card-profile">
        <app-image
          :src="campaignCreator.creator.picture_url"
          :alt="campaignCreator.creator.username"
          class="card-img-top"
        />
        <div class="card-body pt-3">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="avatar avatar-md rounded-circle">
                <app-image
                  :src="campaignCreator.creator.picture_url"
                  :alt="campaignCreator.creator.username"
                />
              </div>
            </div>
            <div class="col ml--3">
              <h5 class="mb-0">
                {{ campaignCreator.creator.name }}
              </h5>
              <p class="text-xs text-muted mb-0">
                @{{ campaignCreator.creator.username }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card-profile-stats d-flex justify-content-center">
                <div>
                  <span class="heading text-sm">
                    {{ campaignCreator.stats.contents_count }}
                  </span>
                  <span class="description text-xs">{{
                    $t('views.app.campaigns.show.contents')
                  }}</span>
                </div>
                <div>
                  <span class="heading text-sm">
                    {{ campaignCreator.stats.comments_count | number('0,00') }}
                  </span>
                  <span class="description text-xs">{{
                    $t('views.app.campaigns.show.comments')
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="text-muted text-left">
            <p class="text-xs">
              {{ $t('views.app.campaigns.show.provider') }}:
              <strong>{{ campaignCreator.creator.provider.name }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-9">
      <app-table type="creator-contents" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  async mounted() {
    await this.campaignCreatorShow({
      campaignId: this.campaignId,
      id: this.campaignCreatorId,
    })
  },

  computed: {
    ...mapGetters({
      campaignCreator: 'campaignCreators/getCampaignCreator',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    campaignCreatorId() {
      return this.$route.params.creatorId
    },
  },

  methods: {
    ...mapActions({
      campaignCreatorShow: 'campaignCreators/show',
    }),
  },
}
</script>
